* {
	box-sizing: border-box;
}

.p-component {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: $fontSize;
    font-weight: $fontWeight;
}

.p-component-overlay {
    background-color: $maskBg;
    transition-duration: $transitionDuration;
}

.p-disabled, .p-component:disabled {
    opacity: $disabledOpacity;
}

.p-error {
    color: $errorColor;
}

.p-text-secondary {
    color: $textSecondaryColor;
}

.pi {
    font-size: $primeIconFontSize;
}

.p-icon {
    width: $primeIconFontSize;
    height: $primeIconFontSize;
}

.p-link {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: $fontSize;
    border-radius: $borderRadius;

    &:focus-visible {
        @include focused();
    }
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
}

.p-component-overlay {
    @keyframes p-component-overlay-enter-animation {
        from {
            background-color: transparent;
        }
        to {
            background-color: var(--maskbg);
        }
    }

    @keyframes p-component-overlay-leave-animation {
        from {
            background-color: var(--maskbg);
        }
        to {
            background-color: transparent;
        }
    }
}

.px-12 {
    padding-left: 12px!important;
    padding-right: 12px!important;
}

.active-link {
    background: #444A57!important;
}

.action-button {
    padding-right: 0.75rem!important;

    &.is-active {
        .p-button-icon {
            transform: rotate(180deg);
        }
    }

    .p-button-label {
        position: relative;
        padding-right: 1rem;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 300%;
            background-color: currentColor;
        }
    }

    .p-button-icon {
        transition: $transitionDuration;

        &-right {
            margin-left: 9px !important;
        }
    }
}

.notification-tab-vuew {
    .t-header {
        padding: 0 8px!important;
    }

    .p-tabview-panels {
        margin: 0 -8px;
    }
}

._focus-visible .t-wrapper::after {
    border: unset!important;
}

.onboarding__main-actions {
    .action-header-main {
        .avatar.medium {
            width: 36px!important;
            height: 36px!important;
        }
    }
    .org .avatar.medium {
        width: 40px!important;
        height: 40px!important;
        font-size: 21px!important;
    }
}

.u-menu-bottom-btn .p-button {
    border-radius: 8px!important;
    width: 100%!important;
    text-align: left;
    padding: 10px 8px!important;
    margin: 0 -8px;
    
    @include hover-focus {
        background: var(--Day-Base-base-02, #F5F7FA)!important;
    }
}

.u-menu-content  {
    .t-header {
        margin: 0 8px;
        width: calc(100% - 8px)!important;
    }

    .accordion-header {
        padding-right: 0!important;
    }
}